<template>
  <div></div>
</template>
<script>
export default {
  mounted() {
    this.$router.replace(this.$store.getters.defaultRoute);
  }
};
</script>
